<template>
  <div>
    <div v-if="isLoading" style="min-height: 500px">
      <LoaderComp />
    </div>
    <div v-else class="" style="width: 100%">
      <!-- Title Input -->
      <div class="mb-3">
        <div class="" style="text-align: end">
          <button @click="openElthInfo()" class="btn">
           <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.8584 3.78076C14.5439 3.46631 14.1714 3.30908 13.7407 3.30908C13.3101 3.29541 12.9375 3.45264 12.623 3.78076C12.3086 4.09521 12.1514 4.46777 12.1514 4.89844C12.1514 5.32227 12.3086 5.69141 12.623 6.00586C12.9375 6.31348 13.3101 6.4707 13.7407 6.47754C14.1714 6.4707 14.5439 6.31348 14.8584 6.00586C15.1729 5.69141 15.3301 5.32227 15.3301 4.89844C15.3301 4.46777 15.1729 4.09521 14.8584 3.78076ZM11.6387 12.4761C11.4609 13.3101 11.208 14.4312 10.8799 15.8394C10.6611 16.7554 10.7329 17.439 11.0952 17.8901C11.4438 18.314 11.9189 18.5464 12.5205 18.5874C13.9424 18.6899 14.7456 17.6235 14.9302 15.3882H14.438C14.1304 17.0288 13.7441 17.8013 13.2793 17.7056C13.0059 17.6509 12.9785 17.1416 13.1973 16.1777L14.3354 11.1738C14.5405 10.2373 14.4688 9.55371 14.1201 9.12305C13.7715 8.69238 13.2964 8.45996 12.6948 8.42578C11.2798 8.32324 10.4766 9.38965 10.2852 11.625H10.7773C11.085 9.97754 11.4712 9.20508 11.936 9.30762C12.2163 9.3623 12.2437 9.87158 12.0181 10.8354L11.6387 12.4761Z" fill="#9C4629"/>
<circle cx="12.5" cy="12.5" r="11" stroke="#9C4629" stroke-width="3"/>
</svg>
          </button>
        </div>
        <div class="text-uppercase" :style="{fontSize:headingStyle.fontSize+'px',
            'font-weight':headingStyle.fontWeight,margin:`${headingStyle.marginTop}px ${headingStyle.marginRight}px ${headingStyle.marginBottom}px ${headingStyle.marginLeft}px`,
            lineHeight:headingStyle.lineHeight, textAlign:headingStyle.textAlign, opacity:headingStyle.opacity, color:headingStyle.color,
            padding:`${headingStyle.paddingTop}px ${headingStyle.paddingRight}px ${headingStyle.paddingBottom}px ${headingStyle.paddingLeft}px`
            }">
          <!-- Update Your Shop -->
         {{ translateCustomText("YOUR ELTH SHOP PAGE")}}
          <!-- YOUR ELTH SHOP PAGE -->
        </div>
      </div>
      <div
        :style="
          style.borderType === 'border'
            ? getInputBorderCSS(style)
            : getInputBorderBottomCSS(style)
        "
        class="d-flex align-items-end"
        style="margin: 0 20px"
      >
        <input
       
          :placeholder="translateCustomText('enter the title of your shop page here (max 30 characters)')"
          class="font-weight-medium input-element CustomTextInputCollection"
          :style="customInputStyles(style)"
          v-model="collection.title"
        />
        <div  class="text-right pr-2 mt-1 text-nowrap">
                      <span :style="collection.title.length < 30 ? 'color:#503a3c' : 'color:#f50d22'">{{
                        collection.title.length
                      }}/30</span>
                    </div>
      </div>
      

      <div class="mt-2">
        <div :style="getMarginCss(style) + getTextAlign(style)">
          <div
            class="d-flex align-items-end"
            :style="
              style.borderType === 'border'
                ? getInputBorderCSS(style)
                : getInputBorderBottomCSS(style)
            "
          >
          <!-- + getDynamicHeight(style) -->
            <input
            
         :placeholder="translateCustomText('enter the description of your shop page here (max 400 characters)')"
          class="font-weight-medium input-element CustomTextInputCollection"
          :style="customInputStyles(style)"
           v-model="collection.description"
        />
        <div  class="text-right pr-2 mt-1 text-nowrap">
                      <span :style="collection.description.length < 400 ? 'color:#503a3c' : 'color:#f50d22'">{{
                        collection.description.length
                      }}/400</span>
                    </div>

         
            <!-- <textarea
              type="text"
              v-model="collection.description"
              
              id="UpdateTextArea"
              rows="0"
              :style="freeTextInputStyles(style) +getDynamicHeight()"
              placeholder="Your shop page’s description (max 500 characters)"
              style="resize: none; overflow: auto;"
              class="productDescription-input UpdateTextArea"
              @keyup="InputAdjust"
              @focus;="InputAdjust"
            />
            -->
          </div> 
        
        </div>
      </div>
      <div class="mt-2 d-flex justify-content-between flex-wrap px-2 px-md-0" >
        <div class="d-flex justify-content-center flex-sm-row flex-column">
           <button class="updateshop p-0" :style="getButtonCss(buttonStyle)+'padding:0px !important;'" @click="updateData">
          <span
            v-if="!submitSpinner"
            :style="{
              opacity: `${buttonStyle.textOpacity}%`,
              fontSize: `${buttonStyle.fontSize}px`,
              whiteSpace:`nowrap`,
            }"
          >
            {{translateCustomText('Update shop')}}
          </span>

          <div v-else class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        
        <button
          style="
            border: 2px solid #9c4629;
            padding: 15px;
            border-radius: 40px;
            height: 42px;
            min-width: 180px;
            margin: 10px 10px 10px 22px;
            display: flex;
            align-items: center;
            text-align: center;
            font-family: Poppins;
            justify-content: center;
            font-weight: 700;
          "
          class="ml-4 ml-sm-3 btn"
         @click="openShop(collection.live_link)"
        >
          <span
            :style="{
              opacity: `${buttonStyle.textOpacity}%`,
              fontSize: `${buttonStyle.fontSize}px`,
              color: `#9C4629`,
              whiteSpace:`nowrap`,
            }"
          >
          {{translateCustomText('View shop')}}
          </span>
        </button>
       
        </div>
        <button v-if="quizId !==2492"    style="
            
            padding: 15px;
            border-radius: 40px;
            height: 42px;
            width: 170px;
            margin: 10px 10px 10px 22px;
            display: flex;
            align-items: center;
            text-align: center;
            font-family: Poppins;
            justify-content: center;
            font-weight: 700;
            background:#503a3c;
            border:none;
          " @click="shareShop(collection.live_link)">
          <span
           
          :style="{
              opacity: `${buttonStyle.textOpacity}%`,
              fontSize: `${buttonStyle.fontSize}px`,
              color: `white`,
              whiteSpace:`nowrap`,
            }"
            class="text-capitalize"
          >
          {{translateCustomText('Share')}}
            
          </span>

        </button>
        <!-- <button
          style="
            
            padding: 15px;
            border-radius: 40px;
            height: 42px;
            width: 170px;
            margin: 10px 10px 10px 22px;
            display: flex;
            align-items: center;
            text-align: center;
            font-family: Poppins;
            justify-content: center;
            font-weight: 700;
            background:#503a3c
          "
          class="ml-3 btn text-capitalize"
          @click="restartQuiz"
        >
          <span
            :style="{
              opacity: `${buttonStyle.textOpacity}%`,
              fontSize: `${buttonStyle.fontSize}px`,
              color: `#ffffff`,
              whiteSpace:`nowrap`,
            }"
          >
          {{translateCustomText('Restart quiz')}}
         
          </span>
        </button> -->
      </div>

      <div class="mt-2" style="margin: 0 20px">
        <div class="mb-1">
          <div class="text-uppercase mb-4" :style="{fontSize:headingStyle.fontSize+'px',
            'font-weight':headingStyle.fontWeight,margin:`${headingStyle.marginTop}px ${headingStyle.marginRight}px ${headingStyle.marginBottom}px ${headingStyle.marginLeft}px`,
            lineHeight:headingStyle.lineHeight, textAlign:headingStyle.textAlign, opacity:headingStyle.opacity, color:headingStyle.color,
            padding:`${headingStyle.paddingTop}px ${headingStyle.paddingRight}px ${headingStyle.paddingBottom}px ${headingStyle.paddingLeft}px`
            }">
            {{ translateCustomText("SHOP PAGE PRODUCTS") }}
          </div>
          <p class="titleSub">
            {{ translateCustomText("View your shop’s products. To remove a product from your shop, click on the Remove button.") }}
           
          </p>
          <!-- <p :style="getTitleStyles(ProductStyles)" class="mb-0 mt-2">
            Products Selected:{{ selectedProducts.length }}
          </p> -->
        </div>
       
        <div>
          <div class="">
            <p
              class="mb-1 mt-2 text-left"
              style="
                color: #503a3c;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
              "
            >
            {{ translateCustomText('N° Products Selected') }}
             =
              <span style="color: #9c4629; font-weight: 700">{{
                getCollectionProductTable.length
              }}</span>
            </p>
          </div>
          <div
            v-if="getCollectionProductTable.length"
            class="TableDiv"
            :style="getScrollCss()"
          >
            <table
              class="table table-striped text-center table-sm w-100 ResponsiveTable"
              id="ClientProductTable"
            >
              <thead
                class="py-3 w-100 stickyHeader"
                style="background: #503a3c; color: #ffffff"
              >
                <tr>
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans; white-space: pre-wrap;"
                  >
                  {{ translateCustomText('Image') }}
                   
                  </th>
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
                  {{ translateCustomText('Title') }}
                    
                  </th>
                  <th  v-if="quizId==baldanQuizID"
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
                    
                    {{ translateCustomText('Code') }}
                  </th>

                   <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
                  {{ translateCustomText('Brand') }}
                    
                  </th>
                  <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
                    
                  {{ translateCustomText('Price') }}

                  </th>
                 
                  <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
                  {{ translateCustomText('Action') }}
                  
                    
                  </th>
                </tr>
              </thead>
              <tbody style="width: 100%">
                <tr
                  v-for="(item, index) in getCollectionProductTable"
                  :key="`${item.id}-${index}`"
                >
                  <td class="ProductInfo" style="vertical-align: middle">
                    <LazyImage
                      :src="ImageSrc(item.image)"
                      :key="`${index}-${item.id}`"
                      style="
                        height: 80px;
                        width: 80px;
                        border-radius: 12px;
                        cursor: pointer;
                      "
                      alt=""
                    />
                  </td>
                  <td style="vertical-align: middle">
                    <p
                      class="ProductTitle ProductInfo m-0"
                      style="cursor: pointer"
                      :style="getTitleStyles(ProductStyles)"
                      @click="openProduct(item)"
                    >
                      {{ item.title }}
                    </p>
                  </td>
                  <td style="vertical-align: middle"  v-if="quizId==baldanQuizID">
                  <p
                    class=" ProductInfo m-0"
                    style="cursor:context-menu"
                    :style="getTitleStyles(ProductStyles)"
                   
                  >
                    {{ item.sku }}
                  </p>
                </td>
                <td style="vertical-align: middle" :style="[quizId==baldanQuizID?{cursor:'context-menu' }:{cursor:'pointer'}]">
                      <p :class="[quizId == baldanQuizID?'':'ProductTitle',' ProductInfo m-0']" 
                        :style="getTitleStyles(ProductStyles)" @click="openVendor(item.vendor)">
                        {{ item.vendor }}
                      </p>
                    </td>
                  <td style="vertical-align: middle">
                    <p
                      class="ProductTitle ProductInfo m-0"
                      style="cursor: pointer"
                      :style="getTitleStyles(ProductStyles)"
                    >
                    {{ quizId==baldanQuizID?'&euro;':'&pound;' }}  {{ item.price }}
                    </p>
                  </td>
                 
                  <td style="vertical-align:middle;" class="  text-center">
                    <div class=" d-flex justify-content-center" style="min-width:95px;">
                      <button
                      class="btn m-0 "
                      @click="removeProductFromCustomCollection(item)"
                      style="
                        border: 1px solid #9c4629;
                        border-radius: 40px;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        font-weight: 700;
                        color: #9c4629;
                      "
                    >
                    {{ translateCustomText('Remove') }}
                      
                    </button>
                    </div>
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <table
              class="table table-striped text-center table-sm ResponsiveTable"
              id="ClientProductTable"
            >
              <thead
                class="py-3 w-100"
                style="background: #503a3c; color: #ffffff"
              >
                <tr>
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans;white-space: pre-wrap;"
                  >
                  {{ translateCustomText('Image') }}
                    
                  </th>
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
                    
                  {{ translateCustomText('Title') }}
                    
                  </th>
                  <th  v-if="quizId==baldanQuizID"
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
                    
                  {{ translateCustomText('Code') }}

                  </th>
                   <th
                    scope="col"
                    class="ProductInfo "
                    style="font-family: Open Sans"
                  >
                    
                  {{ translateCustomText('Brand') }}

                  </th>
                  <th
                    scope="col"
                    class="ProductInfo "
                    style="font-family: Open Sans"
                  >
                    
                  {{ translateCustomText('Price') }}

                  </th>
                 
                  <th
                    scope="col"
                    class="ProductInfo "
                    style="font-family: Open Sans"
                  >
                    
                  {{ translateCustomText('Action') }}

                  </th>
                </tr>
              </thead>
            </table>
            <div class="w-100">
              <p class="mt-2 text-capitalize" style="text-align: center">
              
                </p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="mb-1">
          <div class="mb-4"  :style="{fontSize:headingStyle.fontSize+'px',
            'font-weight':headingStyle.fontWeight,margin:`${headingStyle.marginTop}px ${headingStyle.marginRight}px ${headingStyle.marginBottom}px ${headingStyle.marginLeft}px`,
            lineHeight:headingStyle.lineHeight, textAlign:headingStyle.textAlign, opacity:headingStyle.opacity, color:headingStyle.color,
            padding:`${headingStyle.paddingTop}px ${headingStyle.paddingRight}px ${headingStyle.paddingBottom}px ${headingStyle.paddingLeft}px`
            }">
            <!-- RECOMMENDATIONS -->
            {{ translateCustomText('RECOMMENDATIONS') }}
            <!-- ADD PRODUCTS TO YOUR SHOP -->
          </div>
          <p class="titleSub">
            {{ translateCustomText('View your recommendations. To add a new product to your shop, click on the Add button') }}
          </p>
        </div>
        <div class="elth-filters mb-2">
          <div class="mt-2">
        <div :style="getMarginCss(style) + getTextAlign(style)">
          <div
            class="d-flex align-items-end"
            :style="
              style.borderType === 'border'
                ? getInputBorderCSS(style)
                : getInputBorderBottomCSS(style)
            "
          >
          <!-- + getDynamicHeight(style) -->
            <input
            
            :placeholder="translateCustomText('Search for a product title, brand or code')"
          class="font-weight-medium input-element CustomTextInputCollection"
          :style="customInputStyles(style)"
          
           v-model="filter_text"
        />
       

          </div> 
        
        </div>
      </div>

<div class="row" :style="getMarginCss(style) + getTextAlign(style)">
            <div class="col-md-4 mt-3 p-0" > <b-form-select  v-model="selectedType">
                <b-form-select-option  class="elth-select-option"  value=""><label>{{translateCustomText( 'Type')}}</label></b-form-select-option>
                <!-- <b-form-select-option  class="elth-select-option" value=""><label>{{translateCustomText( 'All')}}</label></b-form-select-option> -->
                <b-form-select-option class="elth-select-option" :value="ProductType.node" v-for="ProductType in  productType" :key="ProductType.node">{{ProductType.node}}</b-form-select-option>
                
              </b-form-select>
            </div>
            <div class="col-md-6 mt-3 px-0 px-md-2"  > <b-form-select v-model="selectedVendor">
                <b-form-select-option class="elth-select-option"  value=""><label>{{translateCustomText( 'Brand')}}</label></b-form-select-option>
                <!-- <b-form-select-option  class="elth-select-option" value=""><label>{{translateCustomText( 'All')}}</label></b-form-select-option> -->
                <b-form-select-option class="elth-select-option" :value="vendor.node" v-for="vendor in  productVendors" :key="vendor.node">{{vendor.node}}</b-form-select-option>
              </b-form-select>
            </div>
            <div class="col-md-2 px-0 mt-3 ">
                <button v-if="showResetFilterBtn"
                    class="btn m-0 float-md-right"
                    @click="resetFilter()"
                    style="
                      border: 1px solid #9c4629;
                      font-size: 16px;
                      text-align: center;
                      font-weight: 700;
                      color: #9c4629;
                      min-width: 114px;
                    "
                  >
                    
                    {{ translateCustomText('Reset') }}
                  </button>
                </div>
          </div>
</div>
        <div :style="getMarginCss(style) + getTextAlign(style)">
          <div class="" >
            <p
              class="mb-1 mt-2 text-left"
              style="
                color: #503a3c;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
              "
            >
            {{ translateCustomText('N° Products Selected') }}

               =
              <span style="color: #9c4629; font-weight: 700">{{
                getQuizResultProductTable.length
              }}</span>
            </p>
          </div>
          <div v-if="getQuizResultProductTable.length" :style="getScrollCss()">
            <table
              class="table table-striped text-center table-sm ResponsiveTable"
              id="ClientProductTable"
            >
              <thead
                class="py-3 w-100 stickyHeader"
                style="background: #503a3c; color: #ffffff"
              >
                <tr>
                  <!-- <th scope="col" style="text-align: center">
                <div class="ProductInfo">
                  <b-form-checkbox
                    id="SelectAllCheckboxResultPage"
                    v-model="selectAllProducts"
                    name="SelectAll"
                    class="SelectAllCheckboxResultPage"
                    @change="selectAllProductsEvent($event)"
                  >
                  </b-form-checkbox>
                </div>
              </th> -->
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans;white-space: pre-wrap;"
                  >
            {{ translateCustomText('Image') }}

                
                  </th>
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Title') }}

                    
                  </th>
                  <th  v-if="quizId==baldanQuizID"
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Code') }}

                    
                  </th>
                   <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Brand') }}
                
                    
                  </th>
                  <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Price') }}

                    
                  </th>
                 
                  <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Action') }}

                    
                  </th>
                </tr>
              </thead>

              <tr
                v-for="(item, index) in  getQuizResultProductTable"
                :key="`${item.id}-${index}`"
              >
                <!-- <td style="text-align: center; width: 100px">
              <div class="ProductInfo">
                <b-form-checkbox
                  :id="`${item.id}-${Date.now()}`"
                  v-model="selectedProducts"
                  :name="`${item.id}-${Date.now()}`"
                  :value="checkConvertIntegar(item.external_id)"
                  @change="toggleSingleProduct"
                  style="cursor: pointer"
                  class="ProductItemCheckbox"
                >
                </b-form-checkbox>
              </div>
            </td> -->

                <td class="ProductInfo" style="vertical-align: middle">
                  <LazyImage
                    :src="ImageSrc(item.image)"
                    :key="`${index}-${item.id}`"
                    style="
                      height: 80px;
                      width: 80px;
                      border-radius: 12px;
                      cursor: pointer;
                    "
                    alt=""
                  />
                </td>
                <td style="vertical-align: middle">
                  <p
                    class="ProductTitle ProductInfo m-0"
                    style="cursor: pointer"
                    :style="getTitleStyles(ProductStyles)"
                    @click="openProduct(item)"
                  >
                    {{ item.title }}
                  </p>
                </td>
                <td style="vertical-align: middle"  v-if="quizId==baldanQuizID">
                  <p
                    class=" ProductInfo m-0"
                    style="cursor: context-menu"
                    :style="getTitleStyles(ProductStyles)"
                   
                  >
                    {{ item.sku }}
                  </p>
                </td>
                <td style="vertical-align: middle" :style="[quizId==baldanQuizID?{cursor:'context-menu' }:{cursor:'pointer'}]">
                      <p :class="[quizId == baldanQuizID?'':'ProductTitle',' ProductInfo m-0']" 
                        :style="getTitleStyles(ProductStyles)" @click="openVendor(item.vendor)">
                        {{ item.vendor }}
                      </p>
                    </td>
                <td style="vertical-align: middle">
                  <p
                    class="ProductTitle ProductInfo m-0"
                    style="cursor: pointer"
                    :style="getTitleStyles(ProductStyles)"
                  >
                  {{ quizId==baldanQuizID?'&euro;':'&pound;' }}  {{ item.price }}
                  </p>
                </td>
                
                <td style="vertical-align: middle">
                  <!-- <p
                class="ProductTitle ProductInfo"
                style="cursor: pointer"
                :style="getTitleStyles(ProductStyles)"
              >
                {{ item.vendor }}
              </p> -->
              <div class=" d-flex justify-content-center">
                  <button
                    class="btn m-0"
                    @click="addProductToCustomCollection(item)"
                    style="
                      border: 1px solid #9c4629;
                      border-radius: 40px;
                      font-size: 15px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      font-weight: 700;
                      color: #9c4629;
                      min-width: 100px;
                      white-space: nowrap;

                    "
                  >
                    
                    {{ translateCustomText('Add') }}
                  </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-else>
            <table
              class="table table-striped text-center table-sm ResponsiveTable"
              id="ClientProductTable"
            >
            <thead
                class="py-3 w-100 stickyHeader"
                style="background: #503a3c; color: #ffffff"
              >
                <tr>
                  <!-- <th scope="col" style="text-align: center">
                <div class="ProductInfo">
                  <b-form-checkbox
                    id="SelectAllCheckboxResultPage"
                    v-model="selectAllProducts"
                    name="SelectAll"
                    class="SelectAllCheckboxResultPage"
                    @change="selectAllProductsEvent($event)"
                  >
                  </b-form-checkbox>
                </div>
              </th> -->
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans;white-space: pre-wrap;"
                  >
            {{ translateCustomText('Image') }}

                
                  </th>
                  <th
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Title') }}

                    
                  </th>
                  <th  v-if="quizId==baldanQuizID"
                    scope="col"
                    class="ProductInfo"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Code') }}

                    
                  </th>
                   <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Brand') }}
                
                    
                  </th>
                  <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Price') }}

                    
                  </th>
                 
                  <th
                    scope="col"
                    class="ProductInfo min-width-tr"
                    style="font-family: Open Sans"
                  >
            {{ translateCustomText('Action') }}

                    
                  </th>
                </tr>
              </thead>
            </table>
            <div class="w-100">
              <p class="mt-2" style="text-align: center">
                {{ translateCustomText('no products found') }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="mt-2">
        <div class="mb-1">
          <p class="mt-2 titleMain"> {{ translateCustomText('YOUR QUIZ RESULTS') }}</p>
          <p class="titleSub">
            {{ translateCustomText('View your quiz answers below. If you would like to retake the quiz, please select Restart quiz.') }}
           
          </p>
        </div>

        <div class="" style="padding: 0 20px">
          <div v-if="quiz_question_history.length">
            <div class="row">
              <div class="col-12">
                <div class="py-2">
                  <div
                    class="mb-3"
                    v-for="(question, index) in quiz_question_history"
                    :key="index"
                  >
                    <div class="">
                      <div class="d-flex align-items-start w-100">
                        <span
                          style="
                            font-size: 20px;
                            color: #503a3c;
                            font-weight: 700;
                            flex:0.1
                          "
                          class="mr-2 mr-sm-0"
                          >Q.{{ index + 1 }}</span
                        >
                        <div class="" style="flex:0.9">
                          <p
                            class="m-0 text-left"
                            style="
                              font-size: 20px;
                              color: #503a3c;
                              font-weight: 700;
                            "
                          >
                            {{ question.question }}
                          </p>
                        </div>
                      </div>

                      <div class="mt-2 pl-4">
                        <div
                          v-for="(ans, index2) in question.options"
                          :key="index2"
                          class="d-flex align-items-center mb-2"
                        >
                          // <i class="fa fa-caret-right mr-2" aria-hidden="true"></i> 
                          <span>&#128073;</span>
                          <div>
                            <div v-if="ans.image">
                              <LazyImage
                                :src="ans.image"
                                alt=""
                                class="img-thumbnail"
                                style="
                                  height: 50px;
                                  width: 50px;
                                  border-radius: 50%;
                                  object-fit: cover;
                                "
                              />
                            </div>
                          </div>
                          <p
                            v-if="ans.value"
                            class="ml-2 mb-0"
                            style="
                              font-size: 18px;
                              color: #9c4629;
                              font-weight: 400;
                            "
                          >
                            {{ ans.value }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p
              :style="getTitleStyles(ProductStyles)"
              class="mb-0 mt-2 font-weight-bold"
              style="font-size: 20px"
            >
            {{ translateCustomText('No Questions found') }}
            </p>
          </div>
        </div>
      </div> -->

      
    </div>
  </div>
</template>

<script>
import LoaderComp from "../customize/component/LoaderComp.vue";
// import { BIconArrowBarUp} from "bootstrap-vue";
import $ from "jquery";
import axios from "axios";
import TranslationMixin from "../customize/mixin/TranslationMixin";
import cssFunction from "../customize/mixin/cssFunction";
import { mapGetters } from "vuex";

export default {
  mixins: [TranslationMixin,cssFunction],
  props: ["ProductStyles", "apiToken", "pageHeight","quizId","headingStyle"],
  data() {
    return {

   
      productType:[],
      productVendors:[],
      selectedVendor:"",
      selectedType:"",
      filter_text:'',
      style: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        textOpacity: 100,
        color: "#9C4629",
        textDecoration: "unset",
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "left",
        fontSize: 14,
        fontFamily: "Poppins",
        strokeOpacity: 100,
        strokeColor: "#9C4629",
        strokeRotation: 0,
        borderSize: 3,
        borderRadius: 0,
        borderType: "border-bottom",
        sendBtnFontWeight: "normal",
        sendBtnFontFamily: "Poppins",
        sendBtnFontSize: 14,
        sendBtnFontStyle: "normal",
        sendBtnTextDecoration: "unset",
        sendBtnBGColor: "#000000",
        sendBtnColor: "#ffffff",
        sendBtnWidth: 150,
        sendBtnHeight: 45,
      },
      buttonStyle: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: "#9C4629",
        color: "#ffffff",
        textAlign: "center",
        fontWeight: "bold",
        fontStyle: "normal",
        buttonWidth: this.quizId==this.baldanQuizID?180:150,
        buttonHeight: "42",
        widthIsAuto: true,
        borderRadius: "40",
        borderSize: "0",
        borderColor: "",
        position: "center",
        textOpacity: 100,
        backgroundOpacity: 100,
        fontSize: "16",
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 22,
        marginRight: 10,
        lineHeight: 1,
        textDecoration: "unset",
        fontFamily: "Poppins",
      },

      collection: {},
      collection_products: [],
      quiz_question_history: [],
      quiz_result_products: [],
      selectedProducts: [],
      isLoading: false,
      selectAllProducts: false,
      submitSpinner: false,
      //   isResponse: false,
      //   errorMsgType: null,
      //   collectionLink: null,
      //   errorMsg:null,
      SuccessMsg: "Congratulations! Your collection has been created.",
    };
  },
  components: {
    // BIconArrowBarUp,
    LoaderComp,
  },
  //   watch: {
  //     Products: {
  //       immediate: true,
  //       deep: true,
  //       handler(newValue) {
  //         this.products = newValue;
  //       },
  //     },
  //   },

  methods: {
    resetFilter(){
      this.selectedVendor=''; 
      this.selectedType=''; 
      this.filter_text=''
    },
    openVendor(vendor) {
      if (vendor&&this.quizId!=this.baldanQuizID) {
        let newVendor = vendor.replace(/ /g, '-')
        let url = `https://elth.co.uk/collections/${newVendor}`;
        window.open(url, "_blank");
      }
    },
    translateCustomText(key){
      return this.translateToItalian(key,this.quizId)   
    },
    shareShop(link) {
      if (link) {
        if(!link.startsWith("https")){
          link= `https://${link}`;
        }
        navigator.clipboard.writeText(link);
          this.$toasted.show(this.translateCustomText(`Link copied!`), {
           position: "bottom-center",
           duration: 2000,
         });
      }
    },
    openShop(link) {
      if (link) {
        if(link.startsWith("https")){
           window.open(link, "_blank");
        } else {
           let url = `https://${link}`;
           window.open(url, "_blank");
        }
        
      }
    },
    openExternalLink(link) {
      window.open(link, "_blank");
    },
    getScrollCss() {
    //    min-width:700px; max-width: 100%;
    // overflow-x: scroll ; 
      return `max-height:335px;height:100%;    overflow-y: scroll;
  scroll-behavior: smooth;`;
    },
    CloseErrorModal() {
      this.collectionLink = null;
      this.errorMsgType = null;
      this.isResponse = false;
      this.errorMsg = null;
      // $("#ClientErrorModal").modal("hide");
    },
    OpenErrorModal() {
      $("#ClientErrorModal").modal("show");
    },
    resetData() {
      this.products = [];
      this.title = null;
      this.description = null;
      this.selectedProducts = [];
    },
    openProduct(product) {
      if (product.detail_link) {
        window.open(product.detail_link, "_blank");
      }
    },
    async updateData() {
      this.submitSpinner = true;
      try {
        let data = {
          title: this.collection.title,
          description: this.collection.description,
          email: localStorage.getItem("already_record_email"),
          api_token: this.apiToken,
        };
        if (this.lastOption) {
          data["image"] = this.lastOption.selectedOption.image;
          // data['image'] =  'https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Desktop_01_1.png?v=1671799809https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Desktop_01_1.png?v=1671799809'
        }

        const response = await axios.post(
          `/api/custom/collection/update`,
          data
        );

        if (response.status == 200) {
          this.getUpdateCollectionData();
        } else {
          this.$toasted.show(this.translateCustomText(`An error has occurred`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(this.translateCustomText(`An error has occurred`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.submitSpinner = false;
      }
    },
    restartQuiz() {
      window.location.href = this.$router.history.current.path;
    },
    getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${
          property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    toggleSingleProduct() {
      this.selectAllProducts = false;
    },
    async selectAllProductsEvent(e) {
      if (e) {
        try {
          this.selectedProducts = await this.products.map((x) =>
            this.checkConvertIntegar(x.external_id)
          );
        } catch (error) {
          if (error) {
            this.$toasted.show(this.translateCustomText(`An error has occurred`), {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        }
      } else {
        this.selectedProducts = [];
      }
    },

    ImageSrc(img) {
      if (img) return img;
      return "/images/default_start_page.png";
    },
    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    InputAdjust(e) {
      let Element = e.target;
      Element.rows = 1;
      const styles = window.getComputedStyle(Element);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      const padding = paddingTop + paddingBottom;
      const initialHeight = (parseInt(styles.height) - padding) / Element.rows;
      const scrollHeight = Element.scrollHeight - padding;
      const newRows = Math.ceil(scrollHeight / initialHeight);
         
      Element.rows = newRows - 1;
    },
    // getDynamicHeight() {
    //   let myEle = document.querySelector(".UpdateTextArea");
     
    // },
    checkConvertIntegar(value) {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return parseInt(value, 10);
      }
    },
    async getUpdateCollectionData() {
      this.isLoading = true;
      try {
        let data = {
          email: localStorage.getItem("already_record_email"),
          api_token: this.apiToken,
        };

        const response = await axios.post(
          `/api/custom/collection/detail`,
          data
        );

        if (response.status == 200) {
          this.collection = response.data.data.collection;
          this.collection_products = response.data.data.collection_products;
          this.quiz_question_history = response.data.data.quiz_question_history;
          this.quiz_result_products = response.data.data.quiz_result_products;
         
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(this.translateCustomText(`An error has occurred`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async addProductToCustomCollection(product) {
      this.isLoading = true;
      try {
        let data = {
          api_token: this.apiToken,
          product_id: product.external_id,
          collection_id: this.collection.collection_id,
        };

        const response = await axios.post(
          `/api/custom/collection/add/product`,
          data
        );

        if (response.status == 200) {
          this.getUpdateCollectionData();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(this.translateCustomText(`An error has occurred`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async removeProductFromCustomCollection(product) {
      this.isLoading = true;
      try {
        let data = {
          api_token: this.apiToken,
          product_id: product.external_id,
          collection_id: this.collection.collection_id,
        };

        const response = await axios.post(
          `/api/custom/collection/remove/product`,
          data
        );

        if (response.status == 200) {
          this.getUpdateCollectionData();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(this.translateCustomText(`An error has occurred`), {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    openElthInfo() {
      window.open(`https://elth-store.freshdesk.com/support/home`, "_blank");
    },
    async getTypesAndVendors(){
    await axios.post('/api/custom/collection/getFilterData',{"api_token":this.apiToken}).then(resp=>{
      if(resp.status==200&&resp.data.status=="success")
      {
        this.productType=resp.data.data.productTypes.edges;
        this.productVendors=resp.data.data.productVendors.edges;
      }
      else{
        this.getTypesAndVendors()
      }
    }).catch(error=>{
      console.log(error)
    })
  },
  },
  computed: {
    showResetFilterBtn(){
    return this.selectedVendor!==''||this.selectedType!==''||this.filter_text!==''?true:false; 
    },
                   getEnv(){
      // ...mapGetters([""]),
      // getEnvConst
      return this.getEnvConst;
    },
      baldanQuizID:()=>this.getEnv?.VUE_APP_ITALIAN_QUIZID??null,
    ...mapGetters(["getSavedLeadID", "getSelectedOptions","getEnvConst"]),
    getCollectionProductTable() {
      return this.collection_products;
    },
    ShopDescription(){
      return this.collection.description
    },
    ShopTitle(){
      return this.collection.title
    },
    lastOption() {
      let length = this.getSelectedOptions.questionAnswer.length;
      return this.getSelectedOptions.questionAnswer[length - 1];
    },
    getQuizResultProductTable() {
      //   return this.quiz_result_products;

      let result = this.quiz_result_products.filter((obj1) =>
        this.getCollectionProductTable.some(
          (obj2) => obj1.external_id == obj2.external_id
        )
      );

      const finalArr = this.quiz_result_products.filter(
        (el) => (!result.includes(el))
        &&((el.vendor!=null&&el.title.toLowerCase().includes(this.filter_text.trim().toLowerCase()))
        || (el.vendor!=null&&el.vendor.toLowerCase().includes(this.filter_text.trim().toLowerCase()))
        ||(el.sku!=null&& el.sku.toLowerCase().includes(this.filter_text.trim().toLowerCase()))
        )&& ((el.vendor!=null&&el.vendor.includes(this.selectedVendor))&&((el.product_type!=null&&el.product_type.includes(this.selectedType))))

      );
      return finalArr;
    },
    isSubmitValid() {
      return this.title && this.selectedProducts.length;
    },
  },
  watch:{
    ShopDescription(newVal, oldVal){
      if (
        newVal.length > 400
      ) {
        this.collection.description = oldVal;
      }
    },
    ShopTitle(newVal, oldVal){
      if (
        newVal.length > 30
      ) {
        this.collection.title = oldVal;
      }
    }
  },
  mounted() {
    this.getTypesAndVendors();
    this.getUpdateCollectionData();
    // $(this.$refs.ClientErrorRef).on("show.bs.modal", this.OpenErrorModal);
    // $(this.$refs.ClientErrorRef).on("hidden.bs.modal", this.CloseErrorModal);
  },
};
</script>

<style scoped>
.input-element {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}
.CustomTextInputCollection {
  /* color: var(--inputColor) !important; */
  color: #503a3c !important;
  font-size: 18px !important;
}
.productDescription-input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding-top:0  ;
  padding-bottom:0 ;
  outline: none;
  color: #503a3c !important;
  font-size: 18px !important;
  /* color: var(--inputTextColor); */
}
.productDescription-input::placeholder,
.input-element::placeholder {
  /* color: var(--inputPlaceholderColor); */
  color: #503a3c;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.productDescription-input::placeholder {
  font-size: 18px !important;
}
/* .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before */
.SelectAllCheckboxResultPage
  >>> #SelectAllCheckboxResultPage.custom-control-input:checked
  ~ label::before {
  background: #9c4629 !important;
}
.ProductItemCheckbox >>> .custom-control-input:checked ~ label::before {
  background: #9c4629 !important;
}

.ProductInfo {
  text-align: center !important;
}
.ProductTitle:hover {
  text-decoration: underline !important;
}
#ClientProductTable td {
  border-top: 1px solid #503a3c !important;
}

.ClientErrorModalContent {
  border-radius: 20px;
  text-align: left;
}
.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}
.TitleSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}
.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}
.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}
.w-90 {
  width: 90%;
}

.titleMain {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  text-align: center;

  color: #503a3c;
}

.titleSub {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  color: #9c4629;
}

/* .TableDiv{
  
  width:100%;
 
} */

@media screen and (max-width: 750px) {
  .ProductInfo {
  margin-bottom:0 !important;
}
  .ResponsiveTable thead{
		display: none;
	}

	.ResponsiveTable, .ResponsiveTable tbody, .ResponsiveTable tr, .ResponsiveTable td{
		display: block;
		width: 100%;
	}
	.ResponsiveTable tr{
		margin-bottom:15px;
   
	}
  #ClientProductTable td {
  border-top: none !important;
}
	.ResponsiveTable td{
		text-align: center;
		/* padding-left: 50%; */
		position: relative;
	}
	.ResponsiveTable td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		text-align: left;
	}
}

.min-width-tr{
  min-width:100px;
}

.stickyHeader{
  position: sticky;
	position: -webkit-sticky;
	top: 0px;
	z-index: 2;
}
.updateshop{
  margin: 10px 10px 10px 20px!important
}
</style>
